.app {
    font-family: Arial, sans-serif;
  }
  
  .header {
    background-color: #ff9999;
    padding: 20px;
    margin-bottom: 20px;
  }
  
  .card-container {
    position: relative;
  }
  
  .card-container .ant-card {
    position: relative;
  }
  
  .ant-card-cover img {
    width: 100%;
    height: auto;
  }
  
  /* Overlay text on the image */
  .overlay {
    position: absolute;
    bottom: 0px;
    z-index: 1;
    color: white;
    background-color: rgba(0, 0, 0, 0.4); /* Optional: Semi-transparent background for better readability */
    padding: 10px;
    border-radius: 5px;
    width: calc(100% - 20px); /* Adjust the width as needed */
  }
  .category-tag {
    color: #fff;
    background-color: #52c41a;
    padding: 4px 8px;
    display: inline-block;
    margin-bottom: 10px;
  }
  .goals {
    background-color: #e9f7ef;
    padding: 40px;
    margin-top: 20px;
  }
  
  .goals .ant-card {
    text-align: center;
  }
  
  .ant-card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .ant-card-cover img {
    max-width: 100%;
    height: auto;
  }

  /* Teaching Method Section */
.teaching-method {
    padding: 40px 20px;
    background-color: #f0f2f5;
  }
  
  
  
  .value-card {
    text-align: center;
  }
  
  .value-card .icon {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
  }
  