.header-container {
  background-color: #228B22; /* Green background */
  padding: 0;
}

.header-menu {
  background-color: #228B22;
  display: flex;
  justify-content: space-between;
  border-bottom: none;
  flex: 1;
  min-width: 700px;
}

.header-menu .ant-menu-item {
  color: white;
  font-size: 16px;
  align-items: center;
  display: flex;
}

.header-menu .ant-menu-item:hover,
.header-menu .ant-menu-item-selected {
  color: #fff !important;
  border-bottom: 2px solid #fff; /* White underline */
}


.lienhe-btn {
  margin-left: auto;
  border: none;
  align-items: center;
  display: flex;
  margin: 5px;
}
.lienhe-btn-mobile {
  margin-left: auto;
  border: none;
  align-items: center;
  display: flex;
  margin: 5px;
}
.lienhe-btn-mobile .ant-btn {
  font-weight: bold;
}

.lienhe-btn .ant-btn {
  background-color: white;
  color: #228B22;
  font-weight: bold;
}

.lienhe-btn .ant-btn:hover {
  background-color: #f0f0f0;
  color: #228B22;
}

.header-menu-desktop {
  display: flex;
  padding: 10px;
}

.header-menu-mobile {
  display: none;
}

/* Mobile view - menu collapses */
@media (max-width: 768px) {
  .header-menu-desktop {
    display: none;
  }

  .header-menu-mobile {
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
  }

  .menu-button {
    font-size: 24px;
    color: white;
  }
}
