.App {
    font-family: Arial, sans-serif;
    padding: 20px;
  }
  
  .header {
    text-align: center;
  }
  
  .profile-info {
    display: flex;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .profile-pic {
    border-radius: 50%;
    margin-right: 20px;
  }
  
  .basic-info {
    max-width: 400px;
  }
  
  .media-section {
    margin-top: 20px;
  }
  
  .media-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
  }
  
  .media-item {
    padding: 10px;
    text-align: center;
    border-radius: 5px;
  }
  
  .media-title {
    font-weight: bold;
  }
  
  button {
    margin-top: 10px;
    padding: 5px 10px;
    background-color: white;
    color: #4CAF50;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .player-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparent;
    z-index: 1;
  }
  .player-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 400px; /* Example width */
    max-height: 200; /* Example height */
  }