.footer {
  background-color: #228B22;
  padding: 40px 20px;
  color: #fff;
  text-align: left;
}

.footer-logo {
  text-align: center;
}

.footer-logo .logo {
  width: 80px;
}

.footer-contact p {
  color: #fff;
  margin: 10px 0;
}

.footer-subscription {
  text-align: center;
}



.footer-bottom {
  margin-top: 20px;
  text-align: center;
}

.social-icon {
  color: #fff;
}
