.news-section {
    padding: 40px 20px;
    background-color: #f5f5f5;
  }
  
  .news-header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .news-header .view-all {
    font-size: 16px;
    color: #52c41a;
  }
  
  .category-tag {
    color: #fff;
    background-color: #52c41a;
    padding: 4px 8px;
    display: inline-block;
    margin-bottom: 10px;
  }
  
  .small-news .small-news-image {
    width: 100%;
    height: auto;
  }
  
  .ant-card-body {
    padding: 16px;
  }
  