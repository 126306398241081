.portrait-gallery {
    background-color: #f5f5f5;
    text-align: center;
  }

  .group-image {
    width: 100%;
    max-height: 400px;
  }
  
  .page-title {
    color: white;
  }
  
  .search-bar {
    margin-top: 20px;
    margin-bottom: 40px;
  }
  .ant-input-search-button {
    margin: 0 !important;
  }
  
  .portraits {
    margin-bottom: 40px;
  }
  
  .student-card {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .student-avatar {
    margin-bottom: 16px;
    border: 4px solid #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .student-name {
    margin-top: 8px;
    font-size: 18px;
    font-weight: bold;
  }
  
  .load-more {
    margin-top: 20px;
  }
  